<template>
    <section v-if="active" :class="$style.pipeline">
        <h2 :class="$style.title">{{ title }}:</h2>

        <dl>

            <dt v-if="general">Total:</dt>
            <dd v-if="general">
                <ProgressBar :progress="general.progress" :text="general.text" />
            </dd>

            <dt v-if="step">Etapa:</dt>
            <dd v-if="step">
                <ProgressBar :progress="step.progress" :text="step.text" />
            </dd>
            
        </dl>

    </section>
</template>

<script>

import ProgressBar from '@/components/ProgressBar'

export default {

    name: 'Pipeline',

    components: {
        ProgressBar
    },

    props: {

        active: {
            type: Boolean,
            default: false
        },

        title: {
            default: 'Atualizando'
        },

        step: {
            default: null
        },

        general: {
            default: null
        }

    }

}

</script>

<style lang="scss" module>

.pipeline {

    margin-bottom: 2rem;

}

.title {

    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 1rem 0;
    text-transform: uppercase;

}

</style>