<template>
    <Box title="Bancos de Dados" icon="server">

        <template #toolbox>

            <ToolBoxButton label="Atualizar Dados" icon="arrow-clockwise" @click="loadData" :loading="loading" />
            <ToolBoxButton v-if="!pipeline.active && !loading && !error" label="Baixar Banco da Produção" icon="download" @click="newDb" :loading="runningNewDb" />

        </template>
                        
        <template #nopadding>

            <Grid :model="model" :data="data" :actions="actions" :loading="loading" :error="error" @actionClick="runAction"></Grid>

        </template>

        <Pipeline :title="pipelineTitle" :active="pipeline.active" :step="pipeline.step" :general="pipeline.general" />
        
    </Box>
</template>

<script>

import EventBus from '@/eventBus'

import Box from '@/components/Box/Box'
import ToolBoxButton from '@/components/Box/ToolBox/ToolBoxButton'
import Grid from '@/components/Grid/Grid'
import Pipeline from '@/components/Pipeline'

export default {

    name: 'DbSection',

    components: {
        Box,
        ToolBoxButton,
        Grid,
        Pipeline,
    },

    props: {

    },

    data() {
        return {

            loading: true,
            error: false,
            runningNewDb: false,
            
            pipeline: {
                active: false,
                timeout: null,
                step: {
                    progress: 0,
                    text: 'Inicializando...'
                },
                general: {
                    progress: 0,
                    text: 'Inicializando...'
                }
            },

            model: [
                { label: 'ID', name: 'dbId' },
                { label: 'Nome', name: 'dbName' },
                { label: 'Ambiente', name: 'envName' },
                { label: 'Status', name: 'dbStatusName' },
                { label: 'Origem', name: 'dbSourceName' },
                { label: 'Gerado em', name: 'insertedIn' }
            ],

            data: [],

            actions: [
                {
                    name: 'delete',
                    title: 'Remover',
                    icon: 'trash'
                }
            ]

        }
    },

    computed: {

        pipelineTitle() {

            let activeDb = this.data.find(db => {
                return [1, 2, 3].includes(db.dbStatusId)
            });

            if(!activeDb)
                return 'Atualizando Banco de Dados';

            return `Atualizando ${activeDb.dbName}`;

        }

    },

    mounted() {

        EventBus.$on('applyDone', this.loadData);

        this.loadData();

    },
    
    methods: {

        runAction(action, db) {

            switch(action.name) {

                case 'delete':
                    this.delete(db);
                    break;

            }

        },

        delete(db) {

            if(db.dbStatusId != 4 && db.dbStatusId != 5) {
                
                alert(`Não é possível excluir o Banco de Dados "${db.dbName}" pois está com o status "${db.dbStatusName}".\n\nApenas é possível excluir com os status "Disponível" e "Cancelado".`);

                return;

            }

            if(confirm(`Deseja realmente remover o Banco de Dados "${db.dbName}"?`)) {

                this.loading = true;

                this.$http.delete(`${this.$store.getters.api}/db/${db.dbId}`)
                    .then(() => {

                        this.loadData();

                    })
                    .catch(() => {
                        alert('Falha ao realizar a exclusão!');
                        this.loading = false;
                    });

            }

        },

        loadData() {

            this.loading = true;
            this.error = false;

            this.$emit('beforeUpdate');

            this.$http.get(`${this.$store.getters.api}/db`)
                .then(response => {

                    this.data = response.data;

                    if(this.data.length) {

                        this.data = this.data.map(db => {

                            return this.dataTransform(db);

                        });

                    }
                    
                    this.$emit('ready', this.data);
                    
                    if(!this.pipeline.active)
                        this.pipelineUpdate();

                })
                .catch(() => {
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        dataTransform(db) {

            return {
                dbId: db.dbId,
                dbStatusId: db.dbStatusId,
                dbName: db.dbName,
                envName: db.env && db.env.length > 0 ? `${db.env[0].envName} (${db.env[0].envId})` : '-',
                dbStatusName: (db.progress && db.progress < 100 ? '(' + db.progress + '%) ' : '') + db.dbStatus.dbStatusName,
                dbSourceName: db.dbSource.dbSourceName,
                insertedIn: this.$moment(db.insertedIn).format("DD/MM/YYYY HH:mm:ss")
            }

        },

        newDb() {

            this.runningNewDb = true;

            this.$http.post(`${this.$store.getters.api}/db`)
                .then(() => {

                    this.loadData();

                }).catch(() => {
                })
                .finally(() => {
                    this.runningNewDb = false;
                });

        },

        pipelineUpdate() {

            clearTimeout(this.pipeline.timeout);

            let activeDb = this.data.find(db => {
                return [1, 2, 3].includes(db.dbStatusId)
            });

            let activeDbIndex = this.data.findIndex(db => {
                return [1, 2, 3].includes(db.dbStatusId)
            });

            if(!activeDb) {
                this.close();
                return;
            }

            this.pipeline.active = true;

            this.$http.get(`${this.$store.getters.api}/db/${activeDb.dbId}`)
                .then(response => {

                    let db = response.data;

                    this.pipeline.general.text = db.dbStatus.dbStatusName;
                    this.pipeline.general.progress = db.progress;
                    
                    this.pipeline.step.text = db.statusObs;
                    this.pipeline.step.progress = db.statusProgress;

                    this.data[activeDbIndex].dbStatusName = this.dataTransform(db).dbStatusName;

                    if(this.pipeline.general.progress == 100)
                        this.close();

                    else
                        this.pipeline.timeout = setTimeout(() => this.pipelineUpdate(), 5000)
                    
                })
                .catch(() => {
                    this.close();
                })
                .finally(() => {
                });

        },

        close() {

            this.$emit('ready', this.data);

            this.pipeline.active = false;

        }

    },

}

</script>