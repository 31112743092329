<template>
    <div>

        <Box title="Ambientes" icon="window">

            <template #toolbox>

                <ToolBoxButton label="Atualizar Dados" icon="arrow-clockwise" @click="loadData" :loading="loading" />

            </template>

            <template #nopadding>
                            
                <Grid :model="model" :data="data" :loading="loading" :error="error"></Grid>

            </template>

            <Pipeline :title="pipelineTitle" :active="pipeline.active" :general="pipeline.general" />
            
        </Box>

        <Box v-if="!error" title="Aplicar Banco de Dados a Ambiente" icon="check">

            <Form>

                <b-row>

                    <b-col md="4">
                        <Dropdown label="Ambiente:" :data="envData" :loading="loading" v-model="apply.envId" />
                    </b-col>

                    <b-col md="3">
                        <Dropdown label="Banco:" :data="$props.dbList" :loading="$props.dbLoading" v-model="apply.dbId" />
                    </b-col>

                    <b-col md="2">
                        <Label />
                        <Button label="Aplicar" icon="play" @click="applyDbOnEnv" :loading="runningApply || $props.dbLoading || loading" fluid />
                    </b-col>

                </b-row>

            </Form>

        </Box>

    </div>
</template>

<script>

import Box from '@/components/Box/Box'
import ToolBoxButton from '@/components/Box/ToolBox/ToolBoxButton'
import Grid from '@/components/Grid/Grid'
import Button from '@/components/Button/Button'
import Form from '@/components/Form/Form'
import Label from '@/components/Form/Label'
import Dropdown from '@/components/Form/Dropdown'
import Pipeline from '@/components/Pipeline'

export default {

    name: 'Env',

    components: {
        Box,
        ToolBoxButton,
        Grid,
        Button,
        Form,
        Label,
        Dropdown,
        Pipeline,
    },

    props: {

        dbList: {
            default: null
        },

        dbLoading: {
            default: true
        }

    },

    data() {
        return {

            loading: true,
            error: false,
            runningApply: false,

            pipeline: {
                active: false,
                timeout: null,
                general: {
                    progress: 0,
                    text: 'Inicializando...'
                }
            },

            apply: {
                dbId: null,
                envId: null,
            },

            model: [
                { label: 'ID', name: 'envId' },
                { label: 'Nome', name: 'envName' },
                { label: 'Banco de Dados', name: 'dbName' },
                { label: 'Usuário do Banco', name: 'dbUser' },
                { label: 'App URL', name: 'appUrl' },
                { label: 'Status', name: 'envStatusName' },
                { label: 'Atualizado em', name: 'updatedIn' }
            ],

            data: []

        }
    },

    computed: {

        envData() {

            return this.data.map(env => {
                return {
                    label: `${env.envName} (${env.appUrl})`,
                    value: env.envId
                }
            });

        },

        pipelineTitle() {

            let activeEnv = this.data.find(env => {
                return env.envStatusId == 5
            });

            if(!activeEnv)
                return 'Atualizando Ambiente';

            return `Atualizando ${activeEnv.envName}`;

        }

    },

    mounted() {

        this.loadData();

    },

    methods: {

        dataTransform(env) {

            return {
                envId: env.envId,
                envName: env.envName,
                dbId: env.dbId,
                dbName: env.db ? `${env.db.dbName} (${env.dbId})` : '-',
                dbUser: env.dbUser,
                appUrl: env.appUrl,
                apiUrl: env.apiUrl,
                envStatusName: env.envStatusId == 5 ? env.envStatus.envStatusName : `(${parseInt((env.envStatusId / 5) * 100)}%) ${env.envStatus.envStatusName}`,
                updatedIn: env.updatedIn ? this.$moment(env.updatedIn).format("DD/MM/YYYY HH:mm:ss") : '-'
            }

        },

        loadData() {

            this.loading = true;
            this.error = false;

            this.$http.get(`${this.$store.getters.api}/env`)
                .then(response => {

                    this.data = response.data;

                    if(this.data.length) {

                        this.data = this.data.map((env) => {
                            return this.dataTransform(env);
                        })

                        this.$emit('ready', this.data);

                    }

                })
                .catch(e => {

                    console.log(e);
                    this.error = true;

                })
                .finally(() => {

                    this.loading = false;

                });

        },

        applyDbOnEnv() {

            if(!this.apply.dbId || !this.apply.envId) {

                alert('Nenhum Banco de Dados ou Ambiente selecionado!');
                return;

            }

            let db = this.dbList.find(db => { return db.value == this.apply.dbId; });
            let env = this.data.find(env => { return env.envId == this.apply.envId; });

            if(!db || !env) {
                
                alert('Nenhum Banco de Dados ou Ambiente localizado!');
                return;

            }

            if(env.dbId == this.apply.dbId) {

                alert('Este Banco de Dados já está aplicado a este Ambiente!');
                return;

            }

            if(confirm(`Deseja realmente aplicar o banco:\n\n"${db.label}"\n\nao ambiente:\n\n${env.envName}`)) {
                
                this.runningApply = true;

                this.$http.post(`${this.$store.getters.api}/env/applyStart`, this.apply)
                    .then(() => {

                        this.pipelineUpdate();

                        this.$http.post(`${this.$store.getters.api}/env/apply`, this.apply)
                            .then(() => {

                                this.$emit('applyReady', this.data);

                                this.loadData();

                            }).catch(e => {

                                console.log(e);
                                alert('Falha ao aplicar a atualização!');

                            })
                            .finally(() => {

                                this.runningApply = false;

                            });

                    }).catch(e => {

                        console.log(e);
                        alert('Falha ao aplicar a atualização!');

                    });
                        
                        
                return;
            }

        },

        pipelineUpdate() {

            clearTimeout(this.pipeline.timeout);

            let activeEnv = this.data.find(env => { return env.dbStatusId != 5; });
            let activeEnvIndex = this.data.findIndex(env => { return env.dbStatusId != 5; });

            if(!activeEnv) {

                this.close();
                return;

            }

            this.pipeline.active = true;

            this.$http.get(`${this.$store.getters.api}/env/${activeEnv.envId}`)
                .then(response => {

                    let env = response.data;

                    this.pipeline.general.text = env.envStatus.envStatusName;
                    this.pipeline.general.progress = parseInt((env.envStatusId / 5) * 100);
                    
                    this.data[activeEnvIndex].envStatusName = this.dataTransform(env).envStatusName;

                    if(this.pipeline.general.progress == 100)
                        this.close();

                    else
                        this.pipeline.timeout = setTimeout(() => this.pipelineUpdate(), 100)
                    
                })
                .catch(e => {
                    
                    console.log(e);
                    this.close();

                });

        },

        close() {

            this.$emit('ready', this.data);

            this.pipeline.active = false;

        }

    }

}

</script>