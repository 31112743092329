<template>
    <section>
        
        <AppBodyHeader />

        <b-container>
            <b-row>
                <b-col>

                    <DbSection @beforeUpdate="dbDataInit" @ready="dbHandle" />
                    
                    <EnvSection :dbLoading="dbLoading" :dbList="dbList" @applyReady="applyHandle" />

                </b-col>
            </b-row>
        </b-container>

    </section>
</template>

<script>

import EventBus from '@/eventBus'

import AppBodyHeader from '@/components/AppBody/AppBodyHeader'
import DbSection from './DbSection'
import EnvSection from './EnvSection'

export default {

    name: 'DatabaseHome',

    components: {
        AppBodyHeader,
        DbSection,
        EnvSection,
    },

    data() {
        return {

            dbLoading: false,
            dbList: [],

        }
    },

    methods: {

        dbDataInit() {

            this.dbLoading = true;

        },

        dbHandle(data) {

            this.dbList = data.filter((db) => {
                return db.dbStatusId == 4 && db.envName == '-'
            }).map((db) => {
                return {
                    label: db.dbName,
                    value: db.dbId
                }
            });

            this.dbLoading = false;

        },

        applyHandle() {

            EventBus.$emit('applyDone');

        }

    }

}

</script>