<template>
    <div :class="$style.container">
        <div :class="$style.bar"></div>
        <div :class="$style.barMask" :style="{ width: (100 - progress) + '%' }"></div>
        <p :class="$style.info">
            {{ text }}<span v-if="text">:</span>
            <strong :class="$style.progress">{{ progress }}%</strong>
        </p>
    </div>
</template>

<script>

export default {

    name: 'ProgressBar',

    props: {

        progress: {
            default: 0
        },

        text: {
            default: null
        }

    }
}

</script>

<style lang="scss" module>

.container {

    border: 2px solid #ccc;
    border-radius: 5px;
    display: block;
    height: 24px;
    position: relative;
    width: 100%;

}

.bar {

    background: #dad43d;
    background-image: linear-gradient(to left, var(--theme-color-1), var(--theme-color-2));
    border: 2px solid #fff;
    border-radius: 5px;
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

}

.barMask {

    background: #fff;
    border-radius: 5px;
    height: 100%;
    position: absolute;
    right: 0;
    transition: all .1s linear;
    top: 0;
    width: 100%;
    z-index: 15;

}

.info {

    align-items: center;
    color: #000;
    display: flex;
    font-size: .6rem;
    font-weight: 900;
    height: 100%;
    margin: 0 0 0 8px;
    position: absolute;
    left: 0;
    text-transform: uppercase;
    top: 0;
    z-index: 20;

}

.progress {

    margin: 0 8px;

}

</style>